import logo from './logo.svg';
import './App.css';
import ApexCharts from 'react-apexcharts';
import  { ImageContainer, LineChartContainer, MainChartContainer,ChartTab,TabContainer, ChartContainer,Tab, TabWrapper } from './components';
import { useState } from 'react';
import{TrafficChart, PopularChart,AttackChart} from './Charts';

function App() {
  const [trafficChart,settrafficChart]=useState(true);
  const [popularChart,setpopularChart]=useState(false);
  const [attackChart,setattackChart]=useState(false);

  const[daily,setDaily]=useState(true);
  const[monthly,setMonthly]=useState(false);


  const handleonTrafficClick=()=>{
   if(!trafficChart)
      settrafficChart(true);
   if(trafficChart)
      settrafficChart(false);
    setpopularChart(false);
    setattackChart(false);
    
  }
  const handleonPopularClick=()=>{
    settrafficChart(false);
    if(!popularChart)
      setpopularChart(true);
   if(popularChart)
      setpopularChart(false);
    setattackChart(false);

  }
  const handleonAttackClick=()=>{
    settrafficChart(false);
    setpopularChart(false);
    if(!attackChart)
     setattackChart(true);
    if(attackChart)
     setattackChart(false);


  }


  return (
        <MainChartContainer>
                    

          <TabWrapper>
          <Tab  onClick={handleonTrafficClick}  style={trafficChart?{ color: 'white',fontWeight: "bold"}:{ color: 'black'}}>Traffic Change</Tab>
         
          <Tab onClick={handleonPopularClick } style={popularChart?{ color: 'white',fontWeight: "bold"}:{ color: 'black'}}
          >Popular Domains</Tab>

          <Tab onClick={handleonAttackClick} style={attackChart?{ color: 'white',fontWeight: "bold"}:{ color: 'black'}}>Attack Layer 3</Tab>
          </TabWrapper>

          
           {trafficChart && 
           <>
           <TabContainer>
           <ChartTab>Daily</ChartTab>
           <ChartTab>Monthly</ChartTab>
       </TabContainer>
       {
          TrafficChart()}
          </>
           }
          
           {popularChart &&
           PopularChart ()}
 
 {attackChart &&
 <>
           <TabContainer>
           <ChartTab onClick={()=>{setDaily(true) ;setMonthly(false)}} style={daily?{ color: 'black',fontWeight: "bold", background:"#FFA500"}:{ color: 'black'}}>Daily</ChartTab>
           <ChartTab onClick={()=>{setDaily(false) ;setMonthly(true)}} style={monthly?{ color: 'black',fontWeight: "bold", background:"#FFA500"}:{ color: 'black'}}>Monthly</ChartTab>
       </TabContainer>
       {daily &&
          AttackChart()}
          {monthly && AttackChart()}
          </>
       }
           


       
        
    {/*        <ApexCharts
          series={[45,66,5,5,44,4,33]
          } options={{series: [{
            name: "Session Duration",
            data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
          },
          {
            name: "Page Views",
            data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35]
          },
          {
            name: 'Total Visits',
            data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47]
          }
        ],
          chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [5, 7, 5],
          curve: 'straight',
          dashArray: [0, 8, 5]
        },
        title: {
          text: 'Page Statistics',
          align: 'left'
        },
        legend: {
          tooltipHoverFormatter: function(val, opts) {
            return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
          }
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan',
            '10 Jan', '11 Jan', '12 Jan'
          ],
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val + " (mins)"
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val + " per session"
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
        }}
} type={'line'} width={'300px'} height={'400px'}>

          </ApexCharts>  */}

</MainChartContainer>

  );
}


export default App;
