export const formatArray=(valueArray)=>{
    const newArray=[];

    valueArray.map((i)=>{
        newArray.push(i*100);
    })
    return newArray;
}


export async function onRequest(context) 
{  const task = await context.env.preview
    .get("data-1"); 
    console.log("task",task) ;
    return new Response(task.data);
}
