import styled from 'styled-components';
import Chart from 'react-apexcharts';
import { formatArray, formatNumber } from './helper';


export const MainChartContainer=styled.div`
width: 100vw;
display: grid;
grid-template-rows: 70px 8px;
height:100vh;
justify-content: center;
//align-items: center;
background:#1F2022	;
padding: 10px;
overflow-x: hidden;
overflow-y: hidden; 
-ms-overflow-style: none;

`;

export const ChartContainer=styled.div`
width:420px;
display: flex;
height:200px;
border-radius:5px;
justify-content: center;
background:#ffffff	;
padding: 20px;
flex-direction: row ;
align-items: center;
`;

export const TabContainer=styled.div`
width:auto;
display: flex;
height:30px;
z-index: 2;
border-radius:0px;
//justify-content: center;
//background:#ffffff	;
gap:0px;
justify-content: flex-start;
padding: 5px;
flex-direction: row ;
align-items: center;
`;



export const TabWrapper=styled.div`
gap:20px;
display:flex;
margin-top:20px;
height:100px;
flex-direction:row;
//align-items: center;

`;

export const Tab=styled.div`
display:flex;
//margin-top:50px;
flex-direction:row;
padding: 10px;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-weight: 500;
width:auto;
height:10px;
align-items: center;
background:#FFA500	;
border-radius:5px;
cursor: pointer;
border: 2px solid #ffffff;
`;

export const ChartTab=styled.div`
display:flex;
margin-top:5px;
flex-direction:row;
padding: 5px;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-weight: 500;
font-size: 10px;
//color:#fffff;
width:auto;
height:8px;
align-items: center;
background:#D3D3D3	;
cursor: pointer;
border-radius: 3px;
border: 1px solid #ffffff;
`;


export const LineChartContainer=styled.div`
margin-top:17px;
height:50%;

p{
    padding:0 10px;
}

div{
    margin-bottom:-30px;
}
`;

//the multi line chart shows the comparison between the total traffic change 
//received from the endpoint over the http traffic change

