import Chart from 'react-apexcharts';
import { formatArray, formatNumber, onRequest } from './helper';
import { ChartContainer, Tab, ChartTab,TabContainer, TabWrapper } from './components';

export  function TrafficChart(data1,data2,xAxis) {
    const series = [
      {
        name: "Total",
        data: onRequest(),
      },
      {
        name: "HTTP",
        data: data2,
      },
    ];
      const options = {
        chart: {
          id: ""
        },
        xaxis: {
          categories: xAxis//will be displayed on the x-asis
        },
        yaxis:{
          labels:{
            formatter:function(value){
              return value.toFixed(2)+'%';
            }
          }
        }
       
        
      };
      return (
        <><ChartContainer>
                  <Chart options={options} type="line" series={series} width="100%" />
              </ChartContainer></>
      );
    }
  
    //
    export  function PopularChart() {
      const series = [
        {
          name: "Previous",
          data: formatArray([0.73,0.67]),
        },
        {
          name: "Current",
          data: formatArray([0.5,0.8764]),
        },
      ];
        const options = {
          chart: {
            id: "",
            animations: {
              speed: 100, //make the transition faster
            },
          },
          xaxis: {
            categories: [1, 2, 3, 4,6,7,5,3] //will be displayed on the x-asis
          },
          yaxis:{
            labels:{
              formatter:function(value){
                return value.toFixed(2)+'%';
              }
            }
          }
         
          
        };
        return (
          <ChartContainer>
            <Chart options={options} type="line" series={series} width="100%" />
            </ChartContainer>
        );
      }
    
      export  function AttackChart() {
        const series = [
          {
            name: "Time of attack",
            data: formatArray([0.73,0.67]),
          },
          
        ];
          const options = {
            chart: {
              id: "",
              animations: {
                speed: 1000, //make the transition faster
              },
            },
            xaxis: {
              categories: [1, 2, 3, 4,6,7,5,3] //will be displayed on the x-asis
            },
            yaxis:{
              labels:{
                formatter:function(value){
                  return value.toFixed(2)+'%';
                }
              }
            }
           
            
          };
          return (
            <ChartContainer>
              <Chart options={options} type="line" series={series} width="100%" />
              </ChartContainer>
          );
        }
      